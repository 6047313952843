import {
  Typography,
  Link,
  Grid,
  Box,
  Drawer,
  Button,
  List,
  ListItem,
} from "@mui/material";
import React from "react";
import logo from "./logo2.png";
import MenuIcon from "@mui/icons-material/Menu";
import "./header.css";

function Header() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <Link href="/about" underline="none" className="link">
            <Typography variant="subtitle1" className="link">
              About
            </Typography>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/packages" underline="none" className="link">
            <Typography variant="subtitle1" className="link">
              Packages
            </Typography>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/faq" underline="none" className="link">
            <Typography variant="subtitle1" className="link">
              FAQ
            </Typography>
          </Link>
        </ListItem>
        <ListItem>
          <Link href="/contact" underline="none" className="link">
            <Typography variant="subtitle1" className="link">
              Contact
            </Typography>
          </Link>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <div className="full-screen-nav">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          sx={{ padding: 2 }}
          style={{
            backgroundColor: "#FDF7F2",
            borderBottomStyle: "solid",
            borderColor: "#d69460",
            color: "#2B2B2B",
          }}
        >
          {/* Left side */}
          <Grid item></Grid>
          <Grid item>
            <Link href="/about" underline="none" className="link">
              <Typography variant="h6" className="link">
                <b> About</b>
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link href="/packages" underline="none" className="link">
              <Typography variant="h6" className="link">
                <b> Packages </b>
              </Typography>
            </Link>
          </Grid>

          {/* Center */}
          <Grid item>
            <Link href="/" underline="none">
              <img
                src={logo}
                alt="Your Logo"
                style={{ width: "225px", height: "150px" }}
              />
            </Link>
          </Grid>

          {/* Right side */}
          <Grid item>
            <Link href="/faq" underline="none" className="link">
              <Typography variant="h6" className="link">
                <b> FAQ</b>
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link href="/contact" underline="none" className="link">
              <Typography variant="h6" className="link">
                <b>Contact </b>
              </Typography>
            </Link>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </div>
      <div className="mobile-screen-nav">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: 2 }}
          style={{
            backgroundColor: "#FDF7F2",
            borderBottomStyle: "solid",
            borderColor: "#d69460",
          }}
        >
          <Link href="/" underline="none">
            <img
              src={logo}
              alt="Your Logo"
              style={{ width: "150px", height: "100px" }}
            />
          </Link>
          <Button onClick={toggleDrawer(true)}>
            <MenuIcon style={{ color: "#D69460" }} />
          </Button>
          <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
            {list()}
          </Drawer>
        </Grid>
      </div>
    </>
  );
}

export default Header;
