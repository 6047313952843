import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./landingpage.css";
import logo from "./logo2.png";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(6, 0),
    textAlign: "center",
    color: theme.palette.text.primary,
    minHeight: "450px", // Ensure the section covers the entire viewport height
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center items vertically
    alignItems: "center", // Center items horizontally
  },
  coverImage: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  textOverlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
    color: "white", // Adjust text color as needed
    padding: theme.spacing(1), // Adjust padding as needed
  },
  textOverlay2: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
    color: "white", // Adjust text color as needed
    padding: theme.spacing(1), // Adjust padding as needed
  },
}));

const LandingPage = () => {
  const reviews = [
    {
      id: 1,
      content:
        "Kayla’s managing of all the technical parts in our wedding allowed for us to be totally present with the events. If there were any hiccups, we had no idea. She has a rare ability to be direct and efficient with ease, calm, and kindness which is exactly what I want in a wedding coordinator. Prior to the wedding we had regular check-ins where I shared where I was in the planning process and she was a safety net making sure I was where I should be. In the weeks leading up to the wedding, she spent hours going through every detail of the day together, and on the day of the wedding, it was executed perfectly. Prior to our cocktail hour, we were able to see the reception set up, and my eyes were glossy to see exactly what I had pictured in my head replicated in front of me. I’m not sure there are words to describe how grateful we are to Kayla and how strongly I would recommend her. Her presence will put you at complete ease.",
      author: "-The Brower's",
      review: (
        <Typography variant="h5" component="h4" gutterBottom>
          <i>
            Kayla’s managing of all the technical parts in our wedding allowed
            for us to be totally present with the events. If there were any
            hiccups, we had no idea. She has a rare ability to be direct and
            efficient with ease, calm, and kindness which is exactly what I want
            in a wedding coordinator. Prior to the wedding we had regular
            check-ins where I shared where I was in the planning process and she
            was a safety net making sure I was where I should be. In the weeks
            leading up to the wedding, she spent hours going through every
            detail of the day together, and on the day of the wedding, it was
            executed perfectly. Prior to our cocktail hour, we were able to see
            the reception set up, and my eyes were glossy to see exactly what I
            had pictured in my head replicated in front of me. I’m not sure
            there are words to describe how grateful we are to Kayla and how
            strongly I would recommend her. Her presence will put you at
            complete ease.
          </i>
        </Typography>
      ),
    },
    {
      id: 2,
      content:
        "There’s too many wonderful things to say about Kayla! But most importantly she’s very personable and friendly. I had no worries leading up on my wedding day because I knew she had it covered. She’s so good about being one step ahead and going the extra mile to make sure we had the best day possible. She thought of so many important details I hadn’t. Even on the wedding day she saved the top of our cake for us without us asking. She’s also highly organized, outgoing and funny! It was a joy to have Kayla apart of our day! I would highly recommend her for a smooth and well planned wedding day.",
      author: "-The Fowler's",
      review: (
        <Typography variant="h5" component="h4" gutterBottom>
          <i>
            There’s too many wonderful things to say about Kayla! But most
            importantly she’s very personable and friendly. I had no worries
            leading up on my wedding day because I knew she had it covered.
            She’s so good about being one step ahead and going the extra mile to
            make sure we had the best day possible. She thought of so many
            important details I hadn’t. Even on the wedding day she saved the
            top of our cake for us without us asking. She’s also highly
            organized, outgoing and funny! It was a joy to have Kayla apart of
            our day! I would highly recommend her for a smooth and well planned
            wedding day.
          </i>
        </Typography>
      ),
    },
    {
      id: 3,
      content:
        "Kayla was ESSENTIAL to creating the most perfect wedding for us. Her attention to detail, organization, creativity, and communication are only the beginning of what makes her amazing. Our vendors were so thankful for her and amazed at the information, and detailed timeline she had prepared for them. Every step along the way ensured the day of our wedding ran smoothly. She helped plan a beautiful outdoor wedding on our family farm that had a ton of moving pieces. We wanted a vintage, dreamy wedding that allowed everyone we loved to spend time together in a place we loved. We knew this would be no easy feat. She helped coordinate 3 different spaces for the ceremony that then flowed to a cocktail hour with games, straight into our reception with dinner served. She listened to what we wanted and made it even more than we could have ever imagined. Even with the big or small issues that inevitably pop up along the way. Less than a week before the wedding we had to switch tents for our reception which in turn completely changed our floor plan. She handled the situation like it was nothing. She immediately had ideas of how to configure the smaller space to fit everyone comfortably, keep a good flow of traffic, and all the small details in between. Kayla helped us create the perfect back up plans for any scenario (especially for an outdoor wedding). Our wedding day was spent enjoying our marriage and with everyone we love, not worried about what would/could go wrong. We can’t say enough about how thankful we were for Radiantly Made LLC. Every moment was PERFECT!",
      author: "- The Coffin's",
      review: (
        <Typography variant="h5" component="h4" gutterBottom>
          <i>
            Kayla was ESSENTIAL to creating the most perfect wedding for us. Her
            attention to detail, organization, creativity, and communication are
            only the beginning of what makes her amazing. Our vendors were so
            thankful for her and amazed at the information, and detailed
            timeline she had prepared for them. Every step along the way ensured
            the day of our wedding ran smoothly. She helped plan a beautiful
            outdoor wedding on our family farm that had a ton of moving pieces.
            We wanted a vintage, dreamy wedding that allowed everyone we loved
            to spend time together in a place we loved. We knew this would be no
            easy feat. She helped coordinate 3 different spaces for the ceremony
            that then flowed to a cocktail hour with games, straight into our
            reception with dinner served. She listened to what we wanted and
            made it even more than we could have ever imagined. Even with the
            big or small issues that inevitably pop up along the way. Less than
            a week before the wedding we had to switch tents for our reception
            which in turn completely changed our floor plan. She handled the
            situation like it was nothing. She immediately had ideas of how to
            configure the smaller space to fit everyone comfortably, keep a good
            flow of traffic, and all the small details in between. Kayla helped
            us create the perfect back up plans for any scenario (especially for
            an outdoor wedding). Our wedding day was spent enjoying our marriage
            and with everyone we love, not worried about what would/could go
            wrong. We can’t say enough about how thankful we were for Radiantly
            Made LLC. Every moment was PERFECT!
          </i>
        </Typography>
      ),
    },
  ];
  const classes = useStyles();
  const theme = useTheme();

  const typographyVariant1 = theme.breakpoints.down("sm") ? "h4" : "h4";
  const typographyVariant = theme.breakpoints.up("sm") ? "h5" : "body1";

  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };
  const copyrightTExt = () => {
    return (
      <i style={{ fontSize: " 10px" }}>
        &nbsp; &nbsp; Copyright Eastlyn & Joshua Photography
      </i>
    );
  };
  return (
    <div>
      <a href="https://www.eastlynandjoshua.com" target="_blank">
        <section
          className={`${classes.section} ${classes.coverImage} firstPhoto`}
        ></section>
      </a>

      {copyrightTExt()}

      <section className={classes.section}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            container
          >
            <Typography
              variant="h3"
              component="h3"
              style={{ color: "#2B2B2B" }}
            >
              Welcome to{" "}
            </Typography>
            <img
              src={logo}
              alt="Your Logo"
              style={{ width: "300px", height: "200px" }}
            />
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Typography
                variant="h5"
                component="h5"
                style={{ color: "#2B2B2B" }}
              >
                <i>
                  A company rooted in faith, focused on bringing your vision to
                  life.
                </i>
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>
      </section>
      <a href="https://www.eastlynandjoshua.com" target="_blank">
        <section
          className={`${classes.section} ${classes.coverImage} tablePhoto`}
        ></section>
      </a>
      {copyrightTExt()}
      <section className={classes.section}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={8} xs={10}>
            <Typography
              variant={typographyVariant1}
              component="h3"
              gutterBottom
            >
              How I stand apart from others
            </Typography>
            <Typography variant={typographyVariant} gutterBottom>
              I am a passionate wedding planner with a keen eye for detail and a
              commitment to you. Your wedding is my sole priority; I only commit
              to one wedding per week, ensuring every aspect of your special day
              is meticulously planned and flawlessly executed. No detail is too
              small. Let’s work together to bring your vision to life, and make
              your wedding as radiant as you.
            </Typography>
          </Grid>
        </Grid>
      </section>
      <a href="https://www.eastlynandjoshua.com" target="_blank">
        <section
          className={`${classes.section} ${classes.coverImage} bridesmaid`}
        ></section>
      </a>
      {copyrightTExt()}
      <section className={classes.section}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton aria-label="previous" onClick={handlePrev}>
            <ArrowBackIos />
          </IconButton>
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            {reviews[index].review}

            <Typography variant={typographyVariant} gutterBottom>
              {reviews[index].author}
            </Typography>
          </div>
          <IconButton aria-label="next" onClick={handleNext}>
            <ArrowForwardIos />
          </IconButton>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
