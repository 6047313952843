import { Typography, List, ListItem, CardContent } from "@mui/material";
function FullService() {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        <b> Full Service $4,000+</b>
      </Typography>
      <Typography variant="body">
        <List dense={true}>
          <ListItem>
            Assist Bride and Groom on creating and sticking to budge
          </ListItem>
          <ListItem>
            Monitor budget along the way to ensure the couple is aware of
            spending patterns and the effect it will have on other portions of
            the budget
          </ListItem>
          <ListItem>
            Create a planning timeline from day of first meeting until the
            conclusion on services/wedding day
          </ListItem>

          <ListItem>
            Vendor recommendations(within Bride and Groom's budget) and contract
            review prior to signing
          </ListItem>
          <ListItem>
            Manage/Set-up meetings with prospective vendors; planner attendance
            optional (up to the couple){" "}
          </ListItem>

          <ListItem>Detailed timeline for vendors </ListItem>
          <ListItem>
            Weekend timeline for Bride and Groom, Bridal party, and family{" "}
          </ListItem>
          <ListItem>
            Bi-weekly/Monthly check-ins with Bride and Groom (to be scheduled
            per couples preference)
          </ListItem>
          <ListItem>Unlimited call/text/email support </ListItem>

          <ListItem>Unlimited call/text/email support </ListItem>
          <ListItem>Unlimited hours on the day of the wedding </ListItem>
          <ListItem>Guest list guidance and recommendations</ListItem>
          <ListItem>Seating chart assistance </ListItem>

          <ListItem>Floor Plan design </ListItem>
          <ListItem>Unlimited hours on the day of the wedding </ListItem>
          <ListItem>Guest list guidance and recommendations</ListItem>
          <ListItem>Seating chart assistance </ListItem>

          <ListItem>
            One hour rehearsal coordination (More than 30 minutes of drive time
            from Kansas City, MO will incur an extra fee).
          </ListItem>
          <ul>
            <li>
              Create and distribute wedding weekend timeline and logistics to
              bridal party and family
            </li>
            <li>
              Coordinate and conduct the rehearsal of all aspects of the
              ceremony.
            </li>
            <li>
              Ensure bride, groom, wedding party, and family are comfortable
              with all ceremony details including procession, and recession.
            </li>
          </ul>

          <ListItem>
            Event day coordination <b> &nbsp;(unlimited hours day-of)</b>
          </ListItem>

          <ul>
            <li>
              Ceremony coordination:
              <ul>
                <li>
                  Assist with pinning boutonnieres and corsages on the wedding
                  party and appropriate friends and family (couple must provide
                  a list ahead of time).
                </li>
                <li>
                  Reserve seats for family and any special requests if needed.
                </li>
                <li>
                  Set up all ceremony personal items, signage, and programs.
                </li>
                <li>
                  Organize and cue all wedding party attendants and family for
                  the procession.
                </li>
                <li>Cue the musicians and all attendants for the ceremony.</li>
                <li>
                  Coordinate the signing of the marriage license with the Bride
                  and Groom, Officiant, and witnesses if necessary.
                </li>
              </ul>
            </li>
            <li>
              Reception and production coordination::
              <ul>
                <li>
                  Complete set up of decor and reception elements. Arrange
                  tables, chairs, floral, and all other decor according to
                  diagrams, photos, and floor plans (couple must provide a
                  set-up team if the venue does not provide one or planner can
                  assist in booking a set-up crew at client cost)
                </li>
                <li>Oversee load-in and vendor set up.</li>
                <li>
                  Coordinator to troubleshoot any issues, keep the event
                  flowing, and on-time.
                </li>
                <li>
                  Set up all personal and last minute items such as place cards,
                  guest book, cake topper, heirloom champagne flutes, and guest
                  favors.
                </li>
                <li>
                  Oversee the day-of wedding timeline and make sure all creative
                  partners are on schedule.
                </li>
                <li>
                  Cue bride, groom, wedding party, and parents for grand
                  entrances, toasts, dances, cake cutting, garter toss, bouquet
                  toss, and grand exit.
                </li>
                <li>
                  Full decor clean up and strike. Items will be packed as needed
                  and sent with a pre-determined/designated person (planner can
                  return items to vendors for an additional fee).
                </li>
              </ul>
            </li>
          </ul>
        </List>
      </Typography>
    </CardContent>
  );
}

export default FullService;
