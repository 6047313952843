import { Button, CardActions, Grid } from "@mui/material";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
}));
function InquireHereButton() {
  const classes = useStyles();
  return (
    <CardActions className={classes.footer}>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          href="/contact"
          style={{ backgroundColor: "#d69460", marginLeft: "auto" }}
        >
          Inquire Here
        </Button>
      </Grid>
    </CardActions>
  );
}
export default InquireHereButton;
