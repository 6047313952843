import { Typography, List, ListItem, CardContent } from "@mui/material";
function DayOfCoordination() {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        <b> Day-of Coordination, starting at $1,600</b>
      </Typography>
      <Typography variant="body">
        <List dense={true}>
          <ListItem>One meeting, 2 weeks out</ListItem>
          <ListItem>Fine tune decor upon planners arrival</ListItem>
          <ListItem>
            Reserve family seats, manage signature/personal items
          </ListItem>
          <ListItem>
            <b>
              8 hours of onsite coordination: coordinate ceremony and reception
            </b>
          </ListItem>
          <ListItem>Bulleted timeline of event</ListItem>
          <ListItem>Week of communication with vendors</ListItem>
        </List>
      </Typography>
    </CardContent>
  );
}

export default DayOfCoordination;
