import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Instagram } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    alignItems: "center",
    miHeight: "10vh",
    padding: "20px",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#FDF7F2",
    borderTopStyle: "solid",
    borderColor: "#d69460",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const currentLocation = window.location.pathname;
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      {currentLocation != "/contact" && (
        <Button
          variant="contained"
          href="/contact"
          style={{ backgroundColor: "#d69460" }}
        >
          Contact
        </Button>
      )}
      <br />

      <Typography variant="h6" style={{ paddingBottom: "10px" }}>
        <i>"Be radiant with the glory and brillance of the Lord." Isiah 60:1</i>
      </Typography>

      <Typography variant="body2">&copy; 2023 Radiantly Made LLC </Typography>

      <Typography variant="body2">
        <Link href="https://www.instagram.com/radiantlymadellc/">
          <Instagram sx={{ color: "#2B2B2B" }} />
        </Link>{" "}
      </Typography>
    </Box>
  );
};

export default Footer;
