import "./App.css";
import Header from "./Header";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Contact from "./Contact/Contact";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import About from "./About/About";
import Services from "./Services/Services";
import CssBaseline from "@mui/material/CssBaseline";
import FAQ from "./FAQ/FAQ";
import Footer from "./Footer";
import LandingPage from "./LandingPage";
const theme = createTheme({
  typography: {
    fontFamily: "Lora, serif", // Specify "Lora" as the font family
    body1: {
      // Customize the body1 variant
      fontFamily: "Lora, serif",
      color: "#2B2B2B",
    },
    body2: {
      // Customize the body2 variant
      fontFamily: "Lora, serif",
    },
    button: {
      fontFamily: "Lora, serif",
      color: "#2B2B2B",
    },
    // You can similarly customize other typography variants like h1, h2, etc.
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Router>
        <Switch>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/packages">
            <Services />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
