import { Typography, List, ListItem, CardContent } from "@mui/material";

function AlaCarte() {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        <b> Al la carte/ Add-On’s</b>
      </Typography>
      <Typography variant="body">
        <List dense={true}>
          <ListItem>
            <b> Rehearsal Dinner full planning/coordination $450+</b>
          </ListItem>
          <ul>
            <li>Assist in finding location, establishing menu/drink options</li>
            <li>Guide client in invitation design</li>
            <li>Assist in the design of the space</li>
            <li>
              Confirm final details with venue such as menu, guest count,
              special dietary restrictions, and special requests of Bride and
              Groom
            </li>
            <li>
              Decorate the rehearsal dinner space according to design plan and
              couple provided decorations
            </li>
            <li>Coordinate strike with venue and Bride/Groom</li>
            <li>
              Additional fee for planner to be on-site throughout entire
              event/dinner
            </li>
          </ul>
          <ListItem>
            <b> Post-Wedding Celebration Brunch coordination $450+</b>
          </ListItem>
          <ul>
            <li>Point of contact with venue/caterer</li>
            <li>Assist with invitations and menu</li>
            <li>
              Supervise room set-up and decor in accordance with design plan
            </li>
            <li>Finalize details with venue/caterer</li>
            <li>Create weekend schedule for guests in attendance</li>
          </ul>
          <ListItem>
            <b> Invitation Coordination $250</b>
          </ListItem>
          <ul>
            <li>
              Assist in design, preparation, and mailing of save the dates and
              wedding invitations
            </li>
          </ul>

          <ListItem>
            <b> RSVP coordination $120</b>
          </ListItem>
          <ul>
            <li>Assist with guest list</li>
            <li>Monitor and track RSVP’s</li>
            <li>
              One week prior to the RSVP deadline, communicate missing RSVP’s to
              the Bride and Groom to contact the guests in time for the final
              count.
            </li>
          </ul>

          <ListItem>
            <b>
              {" "}
              Additional Monthly Check-in’s $100 each (about 1-1.5 hours; couple
              can customize how often monthly, bi-monthly,etc.)
            </b>
          </ListItem>
          <ListItem>
            <b> Additional Event Hourly Rate: $100</b>
          </ListItem>
          <ListItem>
            <b>
              {" "}
              Assistant charge (required for weddings with 150+guests) $200
            </b>
          </ListItem>
          <ListItem>
            <b> Lodging Logistics $200</b>
          </ListItem>
          <ul>
            <li>
              Prepare Lodging Accommodations- Guest Hotel Block, Getting ready
              locations, Honeymoon suite, etc.
            </li>
          </ul>
          <ListItem>
            <b> Coordinate Transportation $150</b>
          </ListItem>
          <ul>
            <li>
              All transportation needs for bride & groom, guests, and bridal
              party for wedding weekend.
            </li>
          </ul>
        </List>
      </Typography>
    </CardContent>
  );
}

export default AlaCarte;
