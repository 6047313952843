import { Typography, List, ListItem, CardContent } from "@mui/material";

function PartialPlanning() {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        <b> Partial Planning & Day-of Coordination, starting at $2,200</b>
      </Typography>
      <Typography variant="body">
        <List dense={true}>
          <ListItem>
            Monthly check-ins (Starting 6 months prior to Wedding day)
          </ListItem>
          <ListItem>
            Unlimited phone/email support from planner (Starting 6 months prior
            to Wedding day)
          </ListItem>
          <ListItem>
            Onsite meeting 4-6 weeks prior to wedding day for final venue walk
            thru and to confirm plan and details
          </ListItem>
          <ListItem>
            Floor plan layout/design recommendations for ceremony and reception
          </ListItem>
          <ListItem>Officiant support with ceremony structure</ListItem>
          <ListItem>Detailed day-of timeline creation</ListItem>
          <ListItem>
            Create a detailed weekend event schedule/timeline for Bride, Groom,
            wedding party, and family
          </ListItem>
          <ListItem>Review vendor contracts provided by client</ListItem>
          <ListItem>
            Confirmation discussions with vendors on specifics and arrival time
          </ListItem>
          <ListItem>One hour rehearsal coordination</ListItem>
          <ul>
            <li>Coordinate and conduct all aspects of the rehearsal.</li>
            <li>
              Ensure the Bride, Groom, wedding party, and family are comfortable
              with all ceremony details including procession and recessional.
            </li>
          </ul>
          <ListItem>
            Event day coordination
            <b>(12 hours included, additional hours can be added for a fee)</b>
          </ListItem>
          <ul>
            <li>
              <b>Ceremony Coordination:</b>{" "}
            </li>
            <ul>
              <li>
                Assist with pinning boutonnieres and corsages on the wedding
                party and appropriate friends and family (the couple will
                provide a list of names ahead of time).
              </li>
              <li>
                Reserve seats for family and any special requests if needed.
              </li>
              <li>
                Set up all ceremony personal items, signage, and programs.
              </li>
              <li>Confirm decor/layout to design plans</li>

              <li>
                Organize and cue all wedding party attendants and family for the
                procession.
              </li>
              <li>Cue the musicians and all attendants for the ceremony.</li>
              <li>
                Coordinate the signing of the marriage license with the Bride
                and Groom, Officiant, and witnesses if necessary.
              </li>
            </ul>
            <li>
              <b>Reception and production coordination:</b>
            </li>
            <ul>
              <li>On-site to oversee delivery and vendor set-up</li>
              <li>
                Vendor point of contact 3-6 months prior to wedding thru the end
                of the celebration
              </li>
              <li>
                Coordinator to troubleshoot any issues, keep events flowing and
                on-time.
              </li>
              <li>
                Supervise set-up crew, ensuring tables, chairs, floral, and all
                other decor is set according to the diagrams and floor plans
                (make sure it’s perfect!).
              </li>

              <li>
                Set up all of your personal and last minute items such as place
                cards, the guest book, and favors.
              </li>

              <li>
                Oversee the wedding timeline and make sure all creative partners
                are on schedule.
              </li>

              <li>
                Execute reception timeline: cue Bride, Groom, wedding party, and
                parents for grand entrances, toasts, dances, cake cutting,
                garter toss, bouquet toss, and grand exit.
              </li>
              <li>
                Supervise clean-up and strike crew (Client must provide a
                clean-up team if the venue does not provide one).
              </li>
            </ul>
          </ul>
        </List>
      </Typography>
    </CardContent>
  );
}

export default PartialPlanning;
