import { Typography } from "@mui/material";
import DayOfCoordination from "./DayOfCoordination";
import MonthOfCoordination from "./MonthOfPlanning";
import PartialPlanning from "./PartialPlanning";
import AlaCarte from "./AlaCarte";
import shoes from "./shoes.jpg";
import bouquet from "./bouqet.jpg";
import PackageCard from "./PackageCard";
import cake from "./cake.jpg";
import table from "./table.jpg";
import table10 from "./table10.jpg";
import FullService from "./FullService";
function Services() {
  return (
    <div
      style={{
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <br />
      <Typography variant="h4" component="div">
        Packages
      </Typography>
      <br />
      <PackageCard
        image={shoes}
        content={<DayOfCoordination />}
        linktrue={true}
      />
      <br />
      <PackageCard
        image={bouquet}
        content={<MonthOfCoordination />}
        linktrue={true}
      />
      <br />
      <PackageCard image={cake} content={<PartialPlanning />} />
      <br />
      <PackageCard image={table10} content={<FullService />} />
      <br />
      <PackageCard image={table} content={<AlaCarte />} />
    </div>
  );
}
export default Services;
