import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import cover from "./cover-flowers.jpg";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import emailjs from "emailjs-com";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent } from "@mui/material";
import logo from "../logo2.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const styles = {
  container: {
    backgroundImage: `url(${cover})`, // Replace with your image path
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    padding: "20px", // Set the height to cover the full viewport height
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "white", // Text color on top of the background
  },
};
function Contact() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const contactMethods = ["Email", "Phone"];
  const hearAbout = [
    "Friend",
    "Venue/Church",
    "Facebook",
    "Instagram",
    "Google",
    "Other",
  ];
  const [services, setServices] = React.useState({
    full: false,
    partial: false,
    month: false,
    day: false,
    carte: false,
    unsure: false,
  });

  const [formData, setFormData] = useState({
    bride: "",
    groom: "",
    email: "",
    phone: "",
    address: "",
    contactMethod: "",
    location: "",
    date: "",
    full: "",
    partial: "",
    month: "",
    day: "",
    carte: "",
    unsure: "",
    hearAbout: "",
    notes: "",
  });
  const { full, partial, month, day, carte, unsure } = services;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleServicesChange = (event) => {
    setServices({
      ...services,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (e) => {
    if (full) {
      formData.full = "Full Planning";
    }
    if (partial) {
      formData.partial = "Partial Planning";
    }
    if (month) {
      formData.month = "Month of Planning/Coordination";
    }
    if (day) {
      formData.day = "Day of Coordination";
    }
    if (carte) {
      formData.carte = "A La Carte";
    }
    if (unsure) {
      formData.unsure = "Not Sure Let's Talk";
    }
    console.log(formData);
    e.preventDefault();
    emailjs
      .send("gmail", "template_isutwl2", formData, "tLMxcWYRtWsgaG5lw")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setFormData({
      bride: "",
      groom: "",
      email: "",
      phone: "",
      address: "",
      contactMethod: "",
      location: "",
      date: "",
      full: "",
      partial: "",
      month: "",
      day: "",
      carte: "",
      unsure: "",
      hearAbout: "",
      notes: "",
    });
    setServices({
      full: false,
      partial: false,
      month: false,
      day: false,
      carte: false,
      unsure: false,
    });
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const copyrightTExt = () => {
    return (
      <a
        href="https://www.eastlynandjoshua.com"
        target="_blank"
        style={{ textDecoration: "none", color: "white" }}
      >
        <i style={{ fontSize: " 12px" }}>
          &nbsp; &nbsp; Copyright Eastlyn & Joshua Photography
        </i>{" "}
      </a>
    );
  };
  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl" style={styles.container}>
        <Paper
          elevation={3}
          style={{
            padding: "16px",
            margin: "auto",
            maxWidth: "600px",
            backgroundColor: "#FDF7F2",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Trust us to make your wedding day as radiant as your love.
            <br />
            Contact us today and let us start planning your happily ever after!
          </p>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Bride's Name"
              name="bride"
              fullWidth
              value={formData.bride}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Groom's Name"
              name="groom"
              fullWidth
              value={formData.groom}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              label="Phone Number"
              name="phone"
              fullWidth
              value={formData.phone}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              label="Address"
              name="address"
              fullWidth
              value={formData.address}
              onChange={handleInputChange}
              margin="normal"
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Preferred Contact Method
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.contactMethod}
                label="Preferred Contact Method"
                onChange={handleInputChange}
                name="contactMethod"
              >
                {contactMethods.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Date of Wedding/Year of Wedding"
              name="date"
              fullWidth
              value={formData.date}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Venue/Location"
              name="location"
              fullWidth
              value={formData.location}
              onChange={handleInputChange}
              margin="normal"
            />
            <FormControl component="fieldset" variant="standard">
              <FormLabel sx={{ m: 1 }}>Services Interested In:</FormLabel>
              <FormGroup
                sx={{
                  marginTop: 0,
                  marginLeft: 3,
                  marginRight: 3,
                  marginBottom: 3,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={full}
                      onChange={handleServicesChange}
                      name="full"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="Full Planning"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={formData.services}
                      checked={partial}
                      onChange={handleServicesChange}
                      name="partial"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="Partial Planning"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={month}
                      onChange={handleServicesChange}
                      name="month"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="Month of Planning/Coordination"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={day}
                      onChange={handleServicesChange}
                      name="day"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="Day of Coordination"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={carte}
                      onChange={handleServicesChange}
                      name="carte"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="A La Carte"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unsure}
                      onChange={handleServicesChange}
                      name="unsure"
                      sx={{
                        "&.Mui-checked": {
                          color: "#D69460",
                        },
                      }}
                    />
                  }
                  label="Not Sure Let's Talk"
                />
              </FormGroup>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                How Did You Hear About Us?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.hearAbout}
                label="How Did You Hear About Us?"
                onChange={handleInputChange}
                name="hearAbout"
              >
                {hearAbout.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Notes/Comments"
              name="notes"
              fullWidth
              value={formData.notes}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={4}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#2B2B2B" }}
            >
              Submit
            </Button>
          </form>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fdf7f2",
                border: "2px solid #000",
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardContent>
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={handleClosePopover}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h5" gutterBottom>
                  Thank you for your inquiry!
                </Typography>
                <Typography variant="body1">
                  I am excited to connect with you to discuss more about your
                  big day. Please allow 1 - 2 business days for a response.
                  <br />
                  Blessings, Kayla
                </Typography>
                <Box sx={{ marginTop: "auto" }}>
                  <img
                    src={logo}
                    alt="Your Logo"
                    style={{
                      width: "300px",
                      height: "200px",
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Popover>
        </Paper>
        {copyrightTExt()}
      </Container>
    </>
  );
}
export default Contact;
