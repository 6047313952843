import InquireHereButton from "./InquireHereButton";
import { Card, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative", // Ensure the position context for the absolute positioning of the button
  },
}));

function PackageCard({ image, content, linktrue }) {
  const classes = useStyles();
  const copyrightTExt = () => {
    return (
      <i style={{ fontSize: " 10px", textAlign: "center" }}>
        &nbsp; &nbsp;Copyright Eastlyn & Joshua Photography
      </i>
    );
  };
  return (
    <Card
      sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
      className={classes.card}
    >
      {linktrue && (
        <a
          href="https://www.eastlynandjoshua.com"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 200,
              minWidth: 200,
              maxHeight: 200,
              minHeight: 200,
              marginTop: 3,
              marginLeft: 3,
            }}
            image={image}
            alt="copyright Eastlyn & Joshua Photography"
          />
          {copyrightTExt()}
        </a>
      )}

      {!linktrue && (
        <CardMedia
          component="img"
          sx={{
            width: 200,
            minWidth: 200,
            maxHeight: 200,
            minHeight: 200,
            marginTop: 3,
            marginLeft: 3,
          }}
          image={image}
          alt="copyright Eastlyn & Joshua Photography"
        />
      )}
      {content}

      <br />
    </Card>
  );
}
export default PackageCard;
