import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import kayla from "./kayla4.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  imageContainer: {
    textAlign: "center",
  },
  image: {
    width: "60%",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
}));
function About() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        {/* Left side with image */}
        <Grid item xs={12} sm={6} className={classes.imageContainer}>
          <img className={classes.image} src={kayla} alt="About Us" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            About
          </Typography>
          <Typography variant="h6">
            Hi there! My name is Kayla Grieshaber. I am the owner and lead
            planner of Radiantly Made. I have been a Pediatric Registered Nurse
            since 2016, which has enhanced my skills in navigating critical
            situations and bridging interpersonal conflicts. When I transitioned
            my career into wedding planning by getting my AAWCP certification in
            2021, I never imagined just how unique and key those skills were.
            Each client has commented on how gracefully I navigate sensitive
            family dynamics, while supporting the couple’s vision and desires. I
            am ecstatic to share my passion, dedication, and expert knowledge
            with you!
          </Typography>
          <br />
          <Typography variant="h6">
            Outside of work, you will find me practicing my faith, traveling the
            world and spending time with friends, family, and my bulldog Mikkah.
            I absolutely love spending time outdoors, whether sitting on my
            patio or hiking and camping around National Parks with my best
            friend! One day I hope to own a little piece of this earth to build
            my own homestead (and possibly a venue!).
          </Typography>
          <br />
          <Typography variant="h6">
            My hopes and dreams for the future of Radiantly Made are to grow my
            business with the same intentionality as I started with, developing
            meaningful connections along the way. From the moment we connect, I
            will be praying for you and your spouse as you both prepare for
            marriage.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
export default About;
