import { Typography, List, ListItem, CardContent } from "@mui/material";

function MonthOfCoordination() {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        <b> Month-of Planning & Day-of Coordination, starting at $1,800</b>
      </Typography>
      <Typography variant="body">
        <List dense={true}>
          <ListItem>
            Onsite meeting or teleconference 4-6 weeks prior to wedding day for
            final walk thru and confirm details and layout
          </ListItem>
          <ListItem>
            Weekly phone/email support from wedding planner (Starting the
            month-of Wedding)
          </ListItem>
          <ListItem>Guidance on floor plan/layout</ListItem>
          <ListItem>
            Vendor point of contact (Starting the month-of Wedding)
          </ListItem>
          <ListItem>
            Review vendor contracts provided by client, and coordinate/confirm
            arrival time with vendors
          </ListItem>
          <ListItem>Create a detailed wedding day timeline</ListItem>
          <ListItem>
            <b>One hour wedding rehearsal coordination</b> <br />
          </ListItem>
          <ul>
            <li>Support the officiant in the rehearsal </li>
            <li>
              Ensure Bride and Groom, wedding party, and family are comfortable
              with all ceremony details
            </li>
          </ul>
          <ListItem>
            <b>
              Event Day-of coordination (10 hours included, additional hours can
              be added for a fee)
            </b>
          </ListItem>
          <ul>
            <li>
              <b>Ceremony Coordination:</b>{" "}
            </li>
            <ul>
              <li>
                Reserve seats for family and any special requests if needed.
              </li>
              <li>
                Set up all ceremony personal items, signage, and programs.
              </li>
              <li>
                Organize and cue all wedding party attendants and family for the
                procession.
              </li>
              <li>Cue the musicians and all attendants for the ceremony.</li>
            </ul>
            <li>
              <b>Reception and production coordination:</b>{" "}
            </li>
            <ul>
              <li>Vendor point of contact (Starting the month-of Wedding)</li>
              <li>
                Coordinator to troubleshoot any issues, keep events flowing and
                on-time.
              </li>
              <li>
                Upon arrival, the wedding planner will fine tune all decor
                details to ensure a flawless look and aesthetic.
              </li>
              <li>
                Set up all of your personal and last minute items such as place
                cards, the guest book, and favors.
              </li>

              <li>
                Oversee the wedding timeline and make sure all creative partners
                are on schedule.
              </li>

              <li>
                Cue bride, groom, wedding party, and parents for grand
                entrances, toasts, dances, cake cutting, garter toss, bouquet
                toss, and grand exit.
              </li>

              <li>
                Coordinate clean-up and strike crew (Client must provide a
                clean-up team if the venue does not provide one)
              </li>
            </ul>
          </ul>
        </List>
      </Typography>
    </CardContent>
  );
}

export default MonthOfCoordination;
